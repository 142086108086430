import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import {
  FieldTextInput,
  FieldToggle,
  IconCheckmark,
  IconInfo,
  PrimaryButton,
} from '../../../../components';
import { Tooltip } from 'react-tooltip';
import { validInstagram, validTikTok, validX, validYoutube } from '../../../../util/validators';

import css from './FieldSocialMedia.module.css';
import { requestSocialMediaVerification } from '../../../../util/api';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../../../ducks/user.duck';

export const FieldSocialMedia = props => {
  const { currentUser, dirty } = props;
  const { metadata, publicData } = currentUser?.attributes?.profile || {};
  const {
    isVerificationInProgress,
    isInstagramVerified,
    isTikTokVerified,
    isXVerified,
    isYouTubeVerified,
  } = metadata?.socialMediaLinksVerification || {};
  const { instagram, tiktok, x, youtube } = publicData?.socialMediaLinks || {};

  const intl = useIntl();
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState();

  const numberOfUnverifiedSocial = useMemo(
    () =>
      [
        instagram ? isInstagramVerified : true,
        tiktok ? isTikTokVerified : true,
        x ? isXVerified : true,
        youtube ? isYouTubeVerified : true,
      ].reduce((acc, curr) => acc + +!curr, 0),
    [currentUser]
  );
  const canVerify = !dirty && !isVerificationInProgress && numberOfUnverifiedSocial > 0;

  const handleSubmit = async () => {
    setInProgress(true);

    await requestSocialMediaVerification({ userId: currentUser.id.uuid });

    dispatch(fetchCurrentUser()).then(() => setInProgress(false));
  };

  return (
    <>
      <div className={css.fieldWithToggle}>
        <FieldTextInput
          className={css.field}
          type="text"
          id="instagram"
          name="instagram"
          label={
            <>
              <span>Instagram</span>
              {isInstagramVerified && <IconCheckmark className={css.verified} />}
            </>
          }
          placeholder="https://instagram.com/your-profile"
          disabled={isVerificationInProgress || isInstagramVerified}
          validate={validInstagram(
            intl.formatMessage({
              id: 'ProfileSettingsForm.socialMediaInvalidInstagram',
            })
          )}
        />
        <FieldToggle
          id="isInstagramShown"
          name="isInstagramShown"
          label={
            <span className={css.tooltipSpan}>
              <a data-tooltip-id="tooltip-isInstagramShown">
                <IconInfo />
              </a>
              <Tooltip
                className={css.tooltip}
                id="tooltip-isInstagramShown"
                place="top"
                content={intl.formatMessage({
                  id: 'ProfileSettingsForm.tooltipContentInstagram',
                })}
                clickable
              />
            </span>
          }
        />
      </div>
      <div className={css.fieldWithToggle}>
        <FieldTextInput
          className={css.field}
          type="text"
          id="tiktok"
          name="tiktok"
          label={
            <>
              <span>TikTok</span>
              {isTikTokVerified && <IconCheckmark className={css.verified} />}
            </>
          }
          placeholder="https://tiktok.com/@your-profile"
          disabled={isVerificationInProgress || isTikTokVerified}
          validate={validTikTok(
            intl.formatMessage({ id: 'ProfileSettingsForm.socialMediaInvalidTikTok' })
          )}
        />
        <FieldToggle
          id="isTikTokShown"
          name="isTikTokShown"
          label={
            <span className={css.tooltipSpan}>
              <a data-tooltip-id="tooltip-isTikTokShown">
                <IconInfo />
              </a>
              <Tooltip
                className={css.tooltip}
                id="tooltip-isTikTokShown"
                place="top"
                content={intl.formatMessage({
                  id: 'ProfileSettingsForm.tooltipContentTikTok',
                })}
                clickable
              />
            </span>
          }
        />
      </div>
      <div className={css.fieldWithToggle}>
        <FieldTextInput
          className={css.field}
          type="text"
          id="x"
          name="x"
          label={
            <>
              <span>X</span>
              {isXVerified && <IconCheckmark className={css.verified} />}
            </>
          }
          placeholder="https://x.com/your-profile"
          disabled={isVerificationInProgress || isXVerified}
          validate={validX(intl.formatMessage({ id: 'ProfileSettingsForm.socialMediaInvalidX' }))}
        />
        <FieldToggle
          id="isXShown"
          name="isXShown"
          label={
            <span className={css.tooltipSpan}>
              <a data-tooltip-id="tooltip-isXShown">
                <IconInfo />
              </a>
              <Tooltip
                className={css.tooltip}
                id="tooltip-isXShown"
                place="top"
                content={intl.formatMessage({
                  id: 'ProfileSettingsForm.tooltipContentX',
                })}
                clickable
              />
            </span>
          }
        />
      </div>
      <div className={css.fieldWithToggle}>
        <FieldTextInput
          className={css.field}
          type="text"
          id="youtube"
          name="youtube"
          label={
            <>
              <span>YouTube</span>
              {isYouTubeVerified && <IconCheckmark className={css.verified} />}
            </>
          }
          placeholder="https://youtube.com/@your-profile"
          disabled={isVerificationInProgress || isYouTubeVerified}
          validate={validYoutube(
            intl.formatMessage({ id: 'ProfileSettingsForm.socialMediaInvalidYouTube' })
          )}
        />
        <FieldToggle
          id="isYouTubeShown"
          name="isYouTubeShown"
          label={
            <span className={css.tooltipSpan}>
              <a data-tooltip-id="tooltip-isYouTubeShown">
                <IconInfo />
              </a>
              <Tooltip
                className={css.tooltip}
                id="tooltip-isYouTubeShown"
                place="top"
                content={intl.formatMessage({
                  id: 'ProfileSettingsForm.tooltipContentYouTube',
                })}
                clickable
              />
            </span>
          }
        />
      </div>
      {canVerify && (
        <>
          <PrimaryButton
            type="button"
            inProgress={inProgress}
            disabled={inProgress}
            onClick={handleSubmit}
          >
            <FormattedMessage
              id="ProfileSettingsForm.requestVerification"
              values={{ numberOfUnverifiedSocial }}
            />
          </PrimaryButton>
          <div className={css.extraInfo}>
            <FormattedMessage id="ProfileSettingsForm.requestVerificationNote" />
          </div>
        </>
      )}
      {isVerificationInProgress && (
        <div className={css.verificationPending}>
          <FormattedMessage id="ProfileSettingsForm.verificationPending" />
        </div>
      )}
    </>
  );
};

FieldSocialMedia.defaultProps = {};

FieldSocialMedia.propTypes = {};

export default FieldSocialMedia;
