import React from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { FieldSelect, InlineTextButton, PrimaryButton } from '../../../../components';
import { FieldArray } from 'react-final-form-arrays';
import { required } from '../../../../util/validators';

import css from './FieldLanguages.module.css';

export const LANGUAGES = [
  { id: 'en', label: 'English' },
  { id: 'es', label: 'Spanish' },
  { id: 'fr', label: 'French' },
  { id: 'de', label: 'German' },
  { id: 'it', label: 'Italian' },
  { id: 'ru', label: 'Russian' },
  { id: 'zh', label: 'Chinese' },
  { id: 'ja', label: 'Japanese' },
  { id: 'ko', label: 'Korean' },
  { id: 'pt', label: 'Portuguese' },
  { id: 'ar', label: 'Arabic' },
  { id: 'hi', label: 'Hindi' },
  { id: 'nl', label: 'Dutch' },
  { id: 'tr', label: 'Turkish' },
  { id: 'pl', label: 'Polish' },
  { id: 'sv', label: 'Swedish' },
  { id: 'no', label: 'Norwegian' },
  { id: 'fi', label: 'Finnish' },
  { id: 'da', label: 'Danish' },
  { id: 'el', label: 'Greek' },
];
export const LANGUAGE_PROFICIENCIES = [
  { id: 'beginner', label: 'Beginner' },
  { id: 'intermediate', label: 'Intermediate' },
  { id: 'advanced', label: 'Advanced' },
  { id: 'native', label: 'Native' },
];

export const FieldLanguages = () => {
  const intl = useIntl();

  return (
    <FieldArray name="languages">
      {({ fields }) => {
        return (
          <div className={css.languages}>
            {fields.map((name, index) => {
              return (
                <div key={`language_${index}`} className={css.row}>
                  <FieldSelect
                    id={`${name}.lang}`}
                    name={`${name}.lang`}
                    label={intl.formatMessage({ id: 'FieldLanguages.languageLangLabel' })}
                    validate={required(intl.formatMessage({ id: 'General.required' }))}
                  >
                    <option disabled value="">
                      {intl.formatMessage({
                        id: 'FieldLanguages.languageLangPlaceholder',
                      })}
                    </option>
                    {LANGUAGES.map(({ id, label }) => (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    ))}
                  </FieldSelect>
                  <FieldSelect
                    id={`${name}.proficiency`}
                    name={`${name}.proficiency`}
                    label={intl.formatMessage({ id: 'FieldLanguages.languageProficiencyLabel' })}
                    validate={required(intl.formatMessage({ id: 'General.required' }))}
                  >
                    <option disabled value="">
                      {intl.formatMessage({
                        id: 'FieldLanguages.languageProficiencyPlaceholder',
                      })}
                    </option>
                    {LANGUAGE_PROFICIENCIES.map(({ id, label }) => (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    ))}
                  </FieldSelect>
                  <PrimaryButton
                    type="button"
                    className={css.removeButton}
                    onClick={() => fields.remove(index)}
                  >
                    <FormattedMessage id="General.remove" />
                  </PrimaryButton>
                </div>
              );
            })}
            <InlineTextButton
              type="button"
              className={css.addItem}
              onClick={() => fields.push({ lang: null, proficiency: null })}
            >
              {fields.length === 0 ? (
                <FormattedMessage id="FieldLanguages.addLanguage" />
              ) : (
                <FormattedMessage id="General.addAnother" />
              )}
            </InlineTextButton>
          </div>
        );
      }}
    </FieldArray>
  );
};

FieldLanguages.defaultProps = {};

FieldLanguages.propTypes = {};

export default FieldLanguages;
