import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  CustomExtendedDataField,
  FieldLocationAutocompleteInput,
  Map,
  H4,
  FieldSelect,
} from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { checkCustomUrlAvailability } from '../../../util/api';
import debounce from 'lodash/debounce';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';
import FieldLanguages from '../../ProfileSettingsPage/ProfileSettingsForm/FieldLanguages/FieldLanguages';
import FieldSocialMedia from '../../ProfileSettingsPage/ProfileSettingsForm/FieldSocialMedia/FieldSocialMedia';

import css from './SignupForm.module.css';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const SignupFormComponent = props => {
  const config = useConfiguration();

  const [checkInProgress, setCheckInProgress] = useState(false);
  const [customURLS, setCustomURLS] = useState({});

  const handleChange = async (customURL, form) => {
    try {
      if (checkInProgress) return;
      setCheckInProgress(true);

      if (!customURL) {
        return form.change('canUse', true);
      }

      const url = customURLS[customURL];
      if (url) {
        return form.change('canUse', url.canUse);
      }

      debounce(async customURL => {
        const { canUse } = await checkCustomUrlAvailability({ customURL });

        form.change('canUse', canUse);

        setCustomURLS({ ...customURLS, [customURL]: { canUse } });
      }, 500)(customURL);
    } finally {
      setCheckInProgress(false);
    }
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={{
        userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes),
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          preselectedUserType,
          userTypes,
          userFields,
          values,
        } = formRenderProps;

        const { userType } = values || {};

        const isProvider = userType === 'provider';

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // Custom user fields. Since user types are not supported here,
        // only fields with no user type id limitation are selected.
        const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

        const noUserTypes = !userType && !(userTypes?.length > 0);
        const userTypeConfig = userTypes.find(config => config.userType === userType);
        const showDefaultUserFields = userType || noUserTypes;
        const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                if (isProvider) {
                  const cleanDisplayName =
                    values?.displayName?.length > 3
                      ? values?.displayName?.replace(/^RR\s*/, '').trim() || ''
                      : '';

                  if (!values?.displayName?.startsWith('RR ')) {
                    form.change('displayName', `RR ${cleanDisplayName}`);
                  }
                  if (cleanDisplayName.length > 1) {
                    handleChange(`RR ${cleanDisplayName}`, form);
                  }
                }
              }}
            />
            <FieldSelectUserType
              name="userType"
              userTypes={userTypes}
              hasExistingUserType={!!preselectedUserType}
              intl={intl}
            />

            {showDefaultUserFields ? (
              <div className={css.defaultUserFields}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={intl.formatMessage({
                    id: 'SignupForm.emailLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.emailPlaceholder',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={intl.formatMessage({
                      id: 'SignupForm.firstNameLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.firstNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.firstNameRequired',
                      })
                    )}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={intl.formatMessage({
                      id: 'SignupForm.lastNameLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.lastNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.lastNameRequired',
                      })
                    )}
                  />
                </div>

                <UserFieldDisplayName
                  formName="SignupForm"
                  className={css.row}
                  userTypeConfig={userTypeConfig}
                  values={values}
                  intl={intl}
                />

                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={intl.formatMessage({
                    id: 'SignupForm.passwordLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
              </div>
            ) : null}

            {showCustomUserFields ? (
              <div className={css.customFields}>
                {userFieldProps.map(fieldProps => (
                  <CustomExtendedDataField {...fieldProps} formId={formId} />
                ))}
              </div>
            ) : null}

            {isProvider ? (
              <>
                <div className={css.row}>
                  <H4 as="h2" className={css.sectionTitle}>
                    <FormattedMessage id="SignupForm.introductory" />
                  </H4>
                  <p>
                    <FormattedMessage id="SignupForm.introductoryDescription" />
                  </p>
                  <UserFieldPhoneNumber
                    formName="SignupForm"
                    userTypeConfig={userTypeConfig}
                    intl={intl}
                  />
                  <div className={css.field}>
                    <label>
                      <FormattedMessage id="SignupForm.dateOfBirthLabel" />
                    </label>
                    <div className={css.dateOfBirth}>
                      <FieldSelect
                        name="monthOfBirth"
                        id={formId ? `${formId}.monthOfBirth` : 'monthOfBirth'}
                      >
                        <option disabled value="">
                          Month
                        </option>
                        {Array.from({ length: 12 }, (v, i) => i + 1).map(n => (
                          <option key={`m${n}`} value={n}>
                            {n}
                          </option>
                        ))}
                      </FieldSelect>
                      <FieldSelect
                        name="dayOfBirth"
                        id={formId ? `${formId}.dayOfBirth` : 'dayOfBirth'}
                      >
                        <option disabled value="">
                          Day
                        </option>
                        {Array.from({ length: 31 }, (v, i) => i + 1).map(n => (
                          <option key={`d${n}`} value={n}>
                            {n}
                          </option>
                        ))}
                      </FieldSelect>
                      <FieldSelect
                        name="yearOfBirth"
                        id={formId ? `${formId}.yearOfBirth` : 'yearOfBirth'}
                      >
                        <option disabled value="">
                          Year
                        </option>
                        {Array.from(
                          { length: 80 },
                          (v, i) => i + new Date().getFullYear() - 80 + 1
                        ).map(n => (
                          <option key={`y${n}`} value={n}>
                            {n}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  </div>
                  <FieldTextInput
                    type="url"
                    id={formId ? `${formId}.introductoryLink` : 'introductoryLink'}
                    name="introductoryLink"
                    className={css.field}
                    label={intl.formatMessage({ id: 'SignupForm.introductoryLabel' })}
                    extraLabel={intl.formatMessage({ id: 'SignupForm.introductoryInfo' })}
                    placeholder={intl.formatMessage({ id: 'SignupForm.introductoryPlaceholder' })}
                  />
                  <FieldSelect
                    type="url"
                    id={formId ? `${formId}.preferredWorkType` : 'preferredWorkType'}
                    name="preferredWorkType"
                    className={css.field}
                    label={intl.formatMessage({ id: 'SignupForm.preferredWorkTypeLabel' })}
                  >
                    <option disabled value="">
                      {intl.formatMessage({
                        id: 'SignupForm.preferredWorkTypePlaceholder',
                      })}
                    </option>
                    <option key="Remote" value="Remote">
                      Remote
                    </option>
                    <option key="In-person" value="In-person">
                      In-person
                    </option>
                    <option key="Both" value="Both">
                      Both
                    </option>
                  </FieldSelect>
                  {values.preferredWorkType !== 'Remote' ? (
                    <FieldTextInput
                      type="textarea"
                      id={formId ? `${formId}.inPersonLocations` : 'inPersonLocations'}
                      name="inPersonLocations"
                      className={css.field}
                      label={intl.formatMessage({ id: 'SignupForm.inPersonLocationsLabel' })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.inPersonLocationsPlaceholder',
                      })}
                    />
                  ) : null}
                </div>
                <div className={css.row}>
                  <H4 as="h2" className={css.sectionTitle}>
                    <FormattedMessage id="SignupForm.locationHeading" />
                  </H4>
                  <FieldLocationAutocompleteInput
                    id={formId ? `${formId}.location` : 'location'}
                    name="location"
                    predictionsClassName={css.predictionsRoot}
                    iconClassName={css.locationAutocompleteInputIcon}
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.location' })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.locationPlaceholder',
                    })}
                    format={v => v}
                    useDefaultPredictions={false}
                  />
                  {values.location?.selectedPlace?.origin && (
                    <div className={css.map}>
                      <Map
                        useStaticMap
                        {...(config.maps.fuzzy.enabled
                          ? {
                              obfuscatedCenter: obfuscatedCoordinates(
                                values.location?.selectedPlace.origin,
                                config.maps.fuzzy.offset,
                                `user#${user.id.uuid}`
                              ),
                            }
                          : {
                              address: values.location?.selectedPlace.address,
                              center: values.location?.selectedPlace.origin,
                            })}
                      />
                    </div>
                  )}
                </div>
                <div className={css.row}>
                  <H4 as="h2" className={css.sectionTitle}>
                    <FormattedMessage id="SignupForm.socialMediaHeading" />
                  </H4>
                  <FieldSocialMedia />
                </div>
                <div className={css.row}>
                  <H4 as="h2" className={css.sectionTitle}>
                    <FormattedMessage id="SignupForm.languageHeading" />
                  </H4>
                  <FieldLanguages />
                </div>
              </>
            ) : null}

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
